<template>
    <Transition
        tag="div"
        name="vuedal"
    >
        <div
            v-show="vuedals.length"
            ref="vuedals"
            class="vuedals"
            tabindex="0"
            @click="handleBackdropClick()"
            @keyup.esc.prevent="handleEscapeKey($event)"
        >
            <div
                v-for="(vuedal, index) in vuedals"
                :key="index"
                class="vuedal"
                :class="getCssClasses(index)"
                @click.stop
            >
                <header v-if="vuedal.dismissable">
                    <div
                        class="vuedal__close"
                        tabindex="0"
                        @click="dismiss()"
                        @keydown.enter="dismiss()"
                    >
                        &times;
                    </div>
                </header>
                <Component
                    v-bind="vuedal.props"
                    :is="vuedal.component"
                    ref="components"
                />
            </div>
        </div>
    </Transition>
</template>

<script>
import Bus from '../bus';

export default {
    name: 'Vuedals',

    data() {
        return {
            vuedals: [],
        };
    },

    computed: {

        current() {
            return this.vuedals[this.$last];
        },

        $last() {
            return this.vuedals.length - 1;
        },

        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            }

            return '';
        },
    },

    created() {
        Bus.$on('new', (options) => {
            const defaults = {
                dismissable: true,
                name: '',
                size: 'sm',
                position: 'center',
                escapable: false,
                closeOnBackdrop: true,
                onClose() {},
                onDismiss() {},
            };

            const opt = Object.assign(defaults, options);

            this.vuedals.push(opt);

            this.$nextTick(() => {
                this.$refs.vuedals.focus();
            });

            Bus.$emit('opened', {
                index: this.$last,
                opt,
            });

            this.body.classList.add('vuedal-open');

            if (this.current.position === 'top') {
                this.body.classList.add('vuedal-top');
            }

            document.querySelector('.vuedals').scrollTop = 0;
        });

        Bus.$on('close', (data) => {
            let index = null;
            const isVue = '_isVue';

            if (data && data.$index) { index = data.$index; }

            if (data && data[isVue]) {
                this.$refs.components.entries().some(([idx, vuedal]) => {
                    if (data === vuedal) {
                        index = idx;
                        return true;
                    }
                    return false;
                });
            }

            if (index === null) { index = this.$last; }

            this.close(data, index);
        });

        Bus.$on('dismiss', (index) => {
            let idx = index;
            if (idx === null) { idx = this.$last; }

            this.dismiss(idx);
        });
    },

    methods: {
        splice(index = null) {
            if (index === -1) { return; }

            if (!this.vuedals.length) { return; }

            if (index === null) { this.vuedals.pop(); } else { this.vuedals.splice(index, 1); }

            if (!this.vuedals.length) {
                this.body.classList.remove('vuedal-open');

                if (this.body.classList.contains('vuedal-top')) {
                    this.body.classList.remove('vuedal-top');
                }

                Bus.$emit('destroyed');
            }
        },

        doClose(index) {
            if (!this.vuedals.length) { return; }

            if (!this.vuedals[index]) { return; }

            this.splice(index);

            const vuedals = document.querySelector('.vuedals');

            if (vuedals) { vuedals.scrollTop = 0; }
        },

        close(data = null, index = null) {
            if (this.vuedals.length === 0) { return; }

            let localIndex = index;

            if (index && typeof index === 'function') {
                localIndex = index(data, this.vuedals);
            }

            if (typeof localIndex !== 'number') { localIndex = this.$last; }

            Bus.$emit('closed', {
                index: localIndex,
                instance: this.vuedals[index],
                data,
            });

            if (localIndex !== false && this.vuedals[localIndex]) {
                this.vuedals[localIndex].onClose(data);
            }

            this.doClose(localIndex);
        },

        dismiss(index = null) {
            if (this.vuedals.length === 0) {
                return;
            }

            let localIndex = index;

            if (index && typeof index === 'function') { localIndex = index(this.$last); }

            if (typeof localIndex !== 'number') { localIndex = this.$last; }

            if (this.vuedals[localIndex].onDismiss() === false) { return; }

            Bus.$emit('dismissed', {
                index: localIndex,
                instance: this.vuedals[localIndex],
            });

            this.doClose(localIndex);
        },

        getCssClasses(index) {
            const vuedal = this.vuedals[index];

            let classNames = `${vuedal.name} _${vuedal.size} _${vuedal.position}`;

            if (index < this.$last) { classNames += ' _disabled'; }

            return classNames;
        },

        handleEscapeKey() {
            if (!this.vuedals.length) { return; }

            if (this.current.escapable) { this.dismiss(); }
        },

        handleBackdropClick() {
            if (!this.vuedals.length) {
                return;
            }

            if (this.current.closeOnBackdrop === true) {
                this.dismiss();
            }
        },
    },
};
</script>

<style lang="postcss">
body.vuedal-open {

    & .bar {
        z-index: 1060;
    }

}

.vuedals {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 500px;
    transition: opacity var(--transition-delay) ease;
}

.vuedal {
    position: absolute;
    background: var(--panel-color);
    box-shadow: 3px 5px 20px var(--layout-color);
    padding: 0;
    margin: 0;
    transition: all var(--transition-delay) ease;
    will-change: transform;
    max-width: 100%;
    width: 100%;

    @media (--mobile) {
        width: 90%;
    }

    &._center {
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        border-radius: 12px;
        margin: auto;
        max-width: 700px;
        max-height: 90vh;
        overflow-y: auto;
    }

    &._right {
        top: 0;
        right: 0;

        & .vuedal__close {
            right: unset;
            left: 5px;
            top: 5px;

            @media (--tablet) {
                left: -60px;
                color: #fff;

                &:hover {
                    color: var(--text-color);
                }
            }
        }

        & .form__errors {
            top: calc(100% + 5px);
            right: 0;
            transform: unset;

            &::before {
                left: unset;
                right: 14px;
                top: -5px;
            }
        }
    }

    &._left {
        top: 0;
        left: 0;

        & .vuedal__close {
            right: 5px;
            top: 5px;

            @media (--tablet) {
                right: -60px;
                color: #fff;

                &:hover {
                    color: var(--text-color);
                }
            }
        }
    }

    &._top {
        top: 0;
        left: 0;
        right: 0;
    }

    &._bottom {
        bottom: 0;
        left: 0;
        right: 0;
    }

    &._xl { max-width: 1024px; }

    &._lg { max-width: 850px; }

    &._sm { max-width: 560px; }

    &._xs { max-width: 350px; }

    &._width-unset {
        max-width: unset;
        width: unset;
    }

    &._disabled {
        opacity: .2;

        &::after {
            background: transparent;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }
    }

    &__close {
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        top: 20px;
        right: 20px;
        font-size: 26px;
        font-weight: 500;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: #a3a5a8;
        vertical-align: middle;
        text-align: center;
        line-height: 40px;
        transition: all var(--transition-delay);

        &:hover {
            background-color: #f0f1f1;
        }
    }

    &.bar-notice-modal,
    &.bar-sidebar-modal,
    &.bar-userMenu-modal {
        width: 100%;
        min-height: 200px;
        padding-top: 56px;

        @media (--mobile) {
            max-width: 334px;
            border-radius: 0 0 0 8px;
        }
    }

    .bar-sidebar-modal {
        background-color: var(--panel-secondary-color);
    }

    .form-modal {
        padding: 24px;
    }
}

.vuedal-enter,
.vuedal-leave-active {
    opacity: 0;
}

.vuedal-enter .vuedal._center,
.vuedal-leave-active .vuedal._center {
    opacity: 0;
}

.vuedal-enter .vuedal._left,
.vuedal-leave-active .vuedal._left {
    opacity: 0;
    left: -100%;
}

.vuedal-enter .vuedal._right,
.vuedal-leave-active .vuedal._right {
    opacity: 0;
    right: -100%;
}

.vuedal-enter .vuedal._top,
.vuedal-leave-active .vuedal._top {
    opacity: 0;
    top: -100%;
}

.vuedal-enter .vuedal._bottom,
.vuedal-leave-active .vuedal._bottom {
    opacity: 0;
    bottom: -100%;
}

.vuedal.order._right {
    max-width: 650px;
    width: 100%;
    padding: 50px 15px 15px;

    @media (--tablet) {
        padding: 50px 20px 20px;
    }

    @media (--desktop) {
        padding: 60px;
    }

    & .form-title_minor {
        display: none;
    }
}
</style>
