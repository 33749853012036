export default [
    {
        path: '/',
        component: () => import('../layouts/LayoutPublic.vue'),
        children: [{
            path: '/login',
            name: 'Enter',
            component: () => import('../views/Auth/Login.vue'),
        },
        ],
    },
    {
        path: '/profile/',
        component: () => import('../layouts/Layout.vue'),
        meta: { authRequired: true },
        children: [
            {
                path: '',
                name: 'profile',
                component: () => import('../views/Auth/Profile.vue'),
            },
        ],
    },
];
