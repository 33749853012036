import Bus from '../bus';
import Component from './component.vue';

const vuedals = {
    name: '$vuedals',

    created() {
        const events = ['opened', 'closed', 'destroyed'];
        events.forEach((event) => {
            Bus.$on(event, (data) => {
                this.$emit(`vuedals:${event}`, data);
            });
        });

        this.$on('new', (options) => {
            this.open(options);
        });

        this.$on('close', (data) => {
            this.close(data);
        });

        this.$on('dismiss', (index) => {
            this.dismiss(index || null);
        });
    },

    open(options = null) {
        Bus.$emit('new', options);
    },

    close(data = null) {
        Bus.$emit('close', data);
    },

    dismiss(index = null) {
        Bus.$emit('dismiss', index);
    },
};

const mixin = {
    created() {
        const events = ['new', 'close', 'dismiss'];
        events.forEach((event) => {
            this.eventBus.on(`vuedals:${event}`, (data) => {
                Bus.$emit(event, data);
            });
        });
    },
};

export default {
    install: (app) => {
        app.config.globalProperties.$vuedals = vuedals;
        app.mixin(mixin);
    },
};

export { Bus, Component };
