import statuses from './statuses';

const {
    loading, success, error,
} = statuses;

export default async function handleApiCall(commit, type, apiCall, onSuccess) {
    commit('setStatus', { type, val: loading });
    try {
        const { data } = await apiCall();
        onSuccess(data);
        commit('setStatus', { type, val: success });
        return Promise.resolve(data);
    } catch (err) {
        commit('setStatus', { type, val: error });
        return Promise.reject(err);
    }
}
