<template>
    <div :class="['overlay', { overlay_loading: appLoading }]">
        <div class="overlay__inner">
            <Component
                v-bind="item.payload"
                :is="item.component"
                v-for="item in components"
                :key="`${item.id}-${item.type}`"
                :window-width="winW"
            >
                <template #closeSnackbar>
                    <div
                        class="overlay__close"
                        tabindex="0"
                        @click="closeModal"
                        @keydown.enter="closeModal"
                    />
                </template>
            </Component>
            <div
                v-show="appLoading"
                class="loader overlay__loader"
            />
        </div>
    </div>
</template>

<script>
import Bus from '../bus';
import Snackbar from './Snackbar.vue';
import { idGenerator } from '../helpers';
import Menu from './Menu.vue';

function findAncestor(el, cls) {
    if (el == null) return el;
    if (el.classList.contains(cls)) {
        return el;
    }

    return findAncestor(el.parentElement, cls);
}

export default {
    name: 'Overlay',
    components: {
        Snackbar,
    },

    props: {
        appLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            components: [],
            winW: 0,
        };
    },

    computed: {
        opened() {
            return this.components.length;
        },
    },

    watch: {
        '$route'() {
            this.resetOverlay();
        },
    },

    mounted() {
        Bus.$on('openSnackbar', this.openSnackbar);
        Bus.$on('closeSnackbar', this.closeSnackbar);
        Bus.$on('openMenu', this.openMenu);
        Bus.$on('closeMenu', this.closeMenu);

        this.$nextTick(() => {
            this.windowWidth();
            window.addEventListener('resize', this.resizeListener);
        });
    },

    beforeUnmount() {
        Bus.$off('openSnackbar', this.openSnackbar);
        Bus.$off('closeSnackbar', this.closeSnackbar);
        Bus.$off('openMenu', this.openMenu);
        Bus.$off('closeMenu', this.closeMenu);

        window.removeEventListener('resize', this.resizeListener);
    },

    methods: {
        resizeListener() {
            this.windowWidth();
        },

        windowWidth() {
            this.winW = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
        },

        resetOverlay() {
            this.components = [];
        },

        closeModal(id) {
            const EL_IDX = this.components.findIndex(el => el.id === id);

            this.components.splice(EL_IDX, 1);
        },

        openSnackbar(opts) {
            const { type = 'info', text, delay = 10000 } = opts;

            const id = idGenerator(4);

            const T = {
                id,
                component: 'Snackbar',
                payload: {
                    type,
                    text,
                },
                type: 'snackbar',
                timeoutId: setTimeout(this.closeSnackbar, delay, id),
            };

            if (this.components.length > 0) {
                const promise = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(this.components.shift());
                    }, 1000);
                });

                promise.then(() => {
                    this.components.push(T);
                });
            } else {
                this.components.push(T);
            }
        },

        closeSnackbar(id) {
            const EL_IDX = this.components.findIndex(el => el.id === id);

            if (EL_IDX < 0) return;

            clearTimeout(this.components[EL_IDX].timerId);
            this.components.splice(EL_IDX, 1);
        },

        openMenu(opts) {
            const { target, component, props = {} } = opts;
            const id = idGenerator(3);

            const T = {
                id,
                component: Menu,
                target,
                payload: {
                    id,
                    target,
                    component,
                    props,
                },
            };

            this.components.push(T);
        },

        closeMenu(id) {
            const EL_IDX = this.components.findIndex(el => el.id === id);

            if (EL_IDX < 0) return;

            this.components.splice(EL_IDX, 1);
        },

        mouseMove(id, cType) {
            const EL_IDX = this.components.findIndex(el => el.id === id);

            if (EL_IDX < 0) return;

            this.mouseMoveWrapper = this.mouseMoveHandler.bind(null, EL_IDX, cType);
            window.addEventListener('mousemove', this.mouseMoveWrapper);
        },

        mouseMoveRemove() {
            window.removeEventListener('mousemove', this.mouseMoveWrapper);
        },

        mouseMoveHandler(idx, cType, e) {
            const EL = this.components[idx];

            if (EL == null || e == null) return;

            if ((findAncestor(e.target, cType) == null)
                && (EL.target.className !== e.target.className)) {
                if (!EL.timerWork) {
                    EL.timerId = setTimeout(this.closeComponent, EL.delay, idx, cType);
                    EL.timerWork = true;
                }
            } else if (EL.timerWork) {
                clearTimeout(EL.timerId);
                EL.timerWork = false;
            }
        },

        closeComponent(idx, cType) {
            if (this.components[idx] == null) return;

            this.mouseMoveRemove();
            delete this.components[idx].target.dataset[cType];
            clearTimeout(this.components[idx].timerId);
            this.components.splice(idx, 1);
        },
    },
};

</script>

<style lang="postcss">

.overlay {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: 1px;
        min-height: 1px;
    }

    &_loading {
        pointer-events: auto;
        background-color: rgba(0, 0, 0, .3);
        transition: background-color var(--transition-delay);

        .overlay__loader {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            margin-left: -25px;
            border-width: 5px;
        }
    }

    &__close {
        background-image: url("../../assets/svg-assets/times.svg");
        background-repeat: no-repeat;
        min-width: 13px;
        width: 13px;
        height: 13px;
        cursor: pointer;
        pointer-events: auto;
        transition: .3s;

        &:hover {
            background-image: url("../../assets/svg-assets/times.svg");
            transform: scale(1.1);
        }
    }
}

</style>
