import * as Sentry from '@sentry/vue';
import mitt from 'mitt';
import { createApp, h } from 'vue';
import App from './App.vue';
import store from './store/index';
import getRouter from './router/index';
import modalPlugin from './modules/modals/main';
import overlay from './modules/overlay/main';
import Overlay from './modules/overlay/Overlay.vue';
import SvgIcon from './components/SvgIcon/SvgIcon.vue';
import AppLoader from './components/Base/AppLoader.vue';
import AppButton from './components/Base/AppButton.vue';
import AppButtonProgress from './components/Base/AppButtonProgress.vue';

import './assets/styles/main.css';

async function initApp() {
    try {
        const app = createApp({
            render: () => h(App),
        });

        Sentry.init({
            app,
            dsn: 'https://894668e392a046478c90343a54c2b1cf@zaochnik1.com/sentry/dsn/7',
            environment: process.env.NODE_ENV,
            ignoreErrors: [
                'Loading CSS chunk [chunkId] failed.',
                /Loading CSS chunk \d+ failed\./,
            ],
        });

        const router = await getRouter();

        app.component('SvgIcon', SvgIcon);
        app.component('Overlay', Overlay);
        app.component('AppLoader', AppLoader);
        app.component('AppButton', AppButton);
        app.component('AppButtonProgress', AppButtonProgress);

        app.use(store);
        app.use(router);
        app.use(modalPlugin);
        app.use(overlay);
        app.config.globalProperties.eventBus = mitt();

        app.mount('#app');
    } catch (error) {
        console.error('Failed to initialize app:', error);
        Sentry.captureException(error);
    }
}

initApp();
