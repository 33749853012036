<template>
    <!-- eslint-disable vue/no-multiple-template-root -->
    <RouterView />
    <Vuedals />
    <Overlay :app-loading="false" />
</template>

<script>
import Vuedals from './modules/modals/component.vue';

export default {
    name: 'App',
    components: {
        Vuedals,
    },
    created() {
        // HACK - исправляет ошибку при обновлении страницы, если пользовтель авторизован
        // * происходит переход на login если пользователь авторизован
        // this.$store.state.auth.isAuthenticated = true;
        // this.$store.dispatch('auth/startup');
        this.$store.dispatch('storeInit');
    },
};
</script>
