<template>
    <svg
        data-test="svg"
        :class="className"
        xmlns="http://www.w3.org/2000/svg"
    >
        <use
            :xlink:href="iconPath"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        />
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconPath() {
            let icon = require(`@/assets/svg-sprite/${this.name}.svg`);

            if (Object.hasOwn(icon, 'default')) {
                icon = icon.default;
            }

            return icon.url;
        },

        className() {
            return `icon icon-${this.name}`;
        },
    },
};
</script>

<style>
.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    fill: var(--text-secondary-color);
    transition: fill var(--transition-delay) ease-in-out, transform var(--transition-delay) ease-in-out;
    vertical-align: middle;
}
</style>
