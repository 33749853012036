export const isType = function (a, type) {
    const B = Object.prototype.toString.call(a).slice(8, -1);
    return a != null && B === type;
};

export const idGenerator = function (length) {
    const CHAR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const CHAR_LENGTH = CHAR.length;
    let res = '';

    for (let i = 0; i < length; i += 1) {
        res += CHAR.charAt(Math.floor(Math.random() * CHAR_LENGTH));
    }

    return res;
};

export const normalizeDate = function (date) {
    if (isType(date, 'Date')) {
        const D = new Date(date);
        const YEAR = D.getFullYear();
        const MONTH = (D.getMonth() + 1).toString().length === 1 ? `0${D.getMonth() + 1}` : D.getMonth() + 1;
        const DATE = D.getDate().toString().length === 1 ? `0${D.getDate()}` : D.getDate();

        return `${YEAR}-${MONTH}-${DATE}`;
    }

    return date;
};

export const arrToUri = function (arr, key) {
    return arr.map(e => `${key}=${e}`).join('&');
};

export const queryFromObject = function (obj) {
    const RES = [];

    Object.keys(obj).forEach((elt) => {
        if (Object.hasOwn(obj, elt)) {
            if (Array.isArray(obj[elt])) {
                RES.push(`${this.arrToUri(obj[elt], elt)}&`);
            } else {
                RES.push(`${elt}=${obj[elt]}`);
            }
        }
    });

    if (RES.length > 0) {
        return `?${RES.join('&')}`;
    }

    return '';
};

export const cleanQuery = function (arr = [], params = []) {
    if (params.length === 0) {
        return arr.join('&');
    }

    const PARAM = params.splice(-1);
    const IDX = arr.findIndex(el => el.includes(PARAM));

    if (IDX >= 0) {
        arr.splice(IDX, 1);
    }

    return cleanQuery(arr, params);
};

export const searchQueryKey = function (param) {
    const { search } = window.location;
    const ARR = search.substring(1).split('&');
    const IDX = ARR.findIndex(el => el.includes(param));

    if (IDX < 0) {
        return null;
    }

    return ARR[IDX].substring(ARR[IDX].indexOf('=') + 1);
};

function transliterateCyrillicToLatin(text) {
    const cyrillicToLatin = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'yo',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'y',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'kh',
        ц: 'ts',
        ч: 'ch',
        ш: 'sh',
        щ: 'sch',
        ь: '',
        ы: 'y',
        ъ: '',
        э: 'e',
        ю: 'yu',
        я: 'ya',
    };

    return text.toLowerCase().replace(/[а-яА-Я]/g, char => cyrillicToLatin[char] || char);
}

export const convertToURL = (text) => {
    const latinText = transliterateCyrillicToLatin(text);

    return latinText
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/--+/g, '-')
        .trim();
};
