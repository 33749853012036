let instance = null;

class EventBus {
    constructor() {
        if (!instance) {
            this.events = {};
            instance = this;
        }
    }

    $emit(event, message) {
        if (!this.events[event]) { return; }
        const callbacks = this.events[event];
        for (let i = 0, l = callbacks.length; i < l; i += 1) {
            const callback = callbacks[i];
            callback.call(this, message);
        }
    }

    $on(event, callback) {
        if (!this.events[event]) { this.events[event] = []; }
        this.events[event].push(callback);
    }

    $off(event) {
        if (!this.events[event]) { return; }
        delete this.events[event];
    }
}

export default new EventBus();
