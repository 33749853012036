import Bus from '../bus';
import Overlay from './Overlay.vue';

const snackbar = {
    name: '$snackBar',
    open(opts) {
        Bus.$emit('openSnackbar', opts);
    },
    close(opts) {
        Bus.$emit('closeSnackbar', opts);
    },
};

const menu = {
    name: '$menuItem',
    open(opts) {
        Bus.$emit('openMenu', opts);
    },
    close(opts) {
        Bus.$emit('closeMenu', opts);
    },
};

export default {
    install: (app) => {
        app.config.globalProperties.$snackbar = snackbar;
        app.config.globalProperties.$menu = menu;
    },
};

export { Overlay };
