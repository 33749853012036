import store from '../store/index';
import loadProject from '../utils/projects';

export default [
    {
        path: '/:catchAll(.*)',
        component: () => import('../views/Main/PageNotFound.vue'),
    },
    {
        path: '/404',
        name: 'notFound',
        component: () => import('../views/Main/PageNotFound.vue'),
    },
    {
        path: '/permission-denied',
        name: 'PermissionDenied',
        component: () => import('../views/Main/PermissionDenied.vue'),
    },
    {
        path: '/500',
        name: 'internalError',
        component: () => import('../views/Main/InternalError.vue'),
    },
    {
        path: '/',
        component: () => import('../layouts/Layout.vue'),
        redirect: { name: 'Home' },
        meta: { authRequired: true },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../components/Widget/WidgetContainer.vue'),
                props: {
                    widgetData: loadProject().navigation,
                },
            },
            {
                path: '/logout',
                name: 'Logout',
                beforeEnter: (to, from, next) => {
                    store.dispatch('auth/logout')
                        .then(() => next(''))
                        .catch(() => next('/404'));
                },
            },
        ],
    },
];
