<template>
    <Transition
        appear
        name="snackbar"
    >
        <div :class="['snackbar', `snackbar_${type}`]">
            <slot name="closeSnackbar" />
            <span>{{ text }}</span>
        </div>
    </Transition>
</template>

<script>

export default {
    name: 'SnackBar',
    props: {
        text: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
    },
};

</script>

<style lang="postcss">

.snackbar {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 15px;
    min-height: 50px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform-origin: center;
    background-color: #323232;
    color: #fff;
    font-size: 14px;
    flex-direction: row-reverse;
    transition: all var(--transition-delay);

    & span {
        margin-left: 35px;
        margin-right: 10px;
    }

    &.snackbar-enter-active,
    &.snackbar-leave-to {
        transform: translateY(-100px);
        opacity: 0;
    }

    &_success {
        background-color: #4caf50;

        &::before {
            content: url("../../assets/svg-assets/checked.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            left: 15px;
        }
    }

    &_error {
        background-color: #f44336;

        &::before {
            content: url("../../assets/svg-assets/alert.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            left: 15px;
        }
    }

    &_info {
        background-color: #2196f3;

        &::before {
            content: url("../../assets/svg-assets/alert.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            left: 15px;
        }
    }
}

</style>
