import * as types from '../actions/application';
import status from '../statuses';

const THEME = {
    light: 'light',
    dark: 'dark',
};

export const COLOR_THEME_VARS = {
    light: {
        '--panel-color': '#fff',
        '--panel-secondary-color': '#fafafa',
        '--panel-tertiary-color': '#f5f5f5',
        '--text-color': '#212121',
        '--text-secondary-color': '#757575',
        '--text-icon-color': '#9e9e9e',
        '--separator-color': '#e0e0e0',
        '--separator-secondary-color': '#f5f5f5',
        '--primary-color': '#3389ec',
        '--primary-50-color': '#c2e7f3',
        '--primary-40-color': '#d7f0f7',
        '--primary-30-color': '#eaf7fb',
        '--primary-20-color': '#f4fbfd',
        '--link-color': '#2c73c7',
        '--danger-color': '#d32f2f',
        '--danger-secondary-color': '#f44336',
        '--danger-separator-color': '#ef9a9a',
        '--danger-background-color': '#ffebee',
        '--success-color': '#2e7d32',
        '--success-secondary-color': '#43a047',
        '--success-separator-color': '#81c784',
        '--success-background-color': '#e8f5e9',
        '--yellow-background-color': '#fff9c4',
        '--box-shadow-chat': `0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)`,
        '--transition-delay': '.3s',
    },

    dark: {
        '--panel-color': '#2d3a41',
        '--panel-secondary-color': '#2d3a41',
        '--panel-tertiary-color': '#34434a',
        '--text-color': '#fff',
        '--text-icon-color': '#9e9e9e',
        '--text-secondary-color': '#9fb3bd',
        '--separator-color': '#455a64',
        '--separator-secondary-color': '#34434a',
        '--primary-color': '#3389ec',
        '--primary-50-color': '#1b4f61',
        '--primary-40-color': '#001e28',
        '--primary-30-color': '#1e4756',
        '--primary-20-color': '#223d47',
        '--link-color': '#2c73c7',
        '--danger-color': '#f37f7d',
        '--danger-secondary-color': '#f44336',
        '--danger-separator-color': '#893b3e',
        '--danger-background-color': '#51363b',
        '--success-color': '#66bb6a',
        '--success-secondary-color': '#2e7d32',
        '--success-separator-color': '#2a5535',
        '--success-background-color': '#284137',
        '--yellow-background-color': '#342d00',
        '--box-shadow-chat': `0px 10px 20px rgba(0, 0, 0, 0.30),
        0px 2px 6px rgba(0, 0, 0, 0.30), 0px 0px 1px rgba(0, 0, 0, 0.04)`,
        '--transition-delay': '.3s',
    },
};

export default {
    namespaced: true,
    state: {
        colorTheme: THEME.light,
        categoryPath: '',
        modal: null,
        status: {
            router: status.empty,
        },
    },

    mutations: {
        [types.SET_STATUS](state, { type, val }) {
            state.status[type] = val;
        },

        // [types.SET_CATEGORY_PATH](state, category = '') {
        //     state.categoryPath = category;
        // },

        [types.SET_COLOR_THEME](state, theme) {
            const BODY_STYLES = document.body.style;
            const VAR_DATA = COLOR_THEME_VARS[theme];
            const REGEXP_PATTERN = /^zao-theme-[a-z]*\b/;
            const REGEXP_FLAG = 'g';
            const THEME_CLASS_NAME = new RegExp(REGEXP_PATTERN, REGEXP_FLAG);

            Object.keys(VAR_DATA).forEach((e) => {
                if (Object.hasOwn(VAR_DATA, e)) {
                    BODY_STYLES.setProperty(e, VAR_DATA[e]);
                }
            });

            if (document.body.className.match(THEME_CLASS_NAME)) {
                document.body.className = document.body.className
                    .replace(THEME_CLASS_NAME, `zao-theme-${theme}`);
            } else {
                document.body.classList.add(`zao-theme-${theme}`);
            }

            state.colorTheme = theme;
            localStorage.setItem('theme', theme);
        },

        [types.MODAL_THROW](state, modal) {
            state.modal = modal;
        },

        [types.MODAL_RESET](state) {
            state.modal = null;
        },
    },

    getters: {
        routerLoading: state => state.status.router === status.loading,
        isMobile() {
            return (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)) !== null;
        },
    },
};
