import http from '../http';
import { setCookie, getCookie } from '../../utils/cookie';

const jwtAccessStored = getCookie('jwt_access');
let jwtExpiresStored = getCookie('jwt_expires');
if (jwtExpiresStored) {
    jwtExpiresStored = new Date(jwtExpiresStored);
}

function timeZone(date) {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    const dtOffset = new Date(d.setMinutes(d.getMinutes() - offset));
    return dtOffset;
}

export default {
    namespaced: true,
    state: {
        jwtAccess: jwtAccessStored || '',
        jwtExpires: jwtExpiresStored || null,
        isAuthenticated: Boolean(jwtExpiresStored && jwtExpiresStored.getTime() > (new Date()).getTime()),
        user: {
            username: '',
            is_admin: false,
            is_staff: false,
            email: '',
            id: null,
            fullname: '',
        },
        refreshingTokenPromise: null,
    },
    mutations: {
        updateAccess(state, payload = {}) {
            state.jwtAccess = payload.access_token;
            state.jwtExpires = timeZone(payload.expires);
            setCookie('jwt_access', state.jwtAccess, 5);
            setCookie('jwt_expires', state.jwtExpires, 5);
            state.isAuthenticated = true;
        },

        clearAccess(state) {
            state.jwtAccess = '';
            setCookie('jwt_access', '', -1);
            setCookie('jwt_expires', '', -1);
            state.jwtExpires = null;
            state.isAuthenticated = false;
        },

        storeUser(state, payload) {
            state.user = payload;
        },

        setRefreshingTokenPromise(state, promise) {
            state.refreshingTokenPromise = promise;
        },
    },
    actions: {
        async login({ commit }, payload) {
            const { data, status } = await http.post('/auth/', payload);
            if (status === 200 || status === 201) {
                commit('updateAccess', data);
            }
        },

        refresh({ commit, state }) {
            if (state.refreshingTokenPromise) {
                return state.refreshingTokenPromise;
            }

            const promise = new Promise((resolve, reject) => {
                http.patch('/auth/')
                    .then(({ data }) => {
                        commit('updateAccess', data);
                        resolve(data);
                    })
                    .catch((error) => {
                        const { response: errResponse = {} } = error;

                        if (errResponse.status === 401 || errResponse.status === 400) {
                            commit('clearAccess');
                            window.location.replace(`${window.location.origin}/login/`);
                        }

                        reject(error);
                    })
                    .finally(() => {
                        commit('setRefreshingTokenPromise', null);
                    });
            });

            commit('setRefreshingTokenPromise', promise);
            return promise;
        },

        async logout({ commit }) {
            try {
                await http.delete('/auth/');
                const { origin } = window.location;
                const targetPath = `${origin}/login/`;

                commit('clearAccess');
                window.location.replace(targetPath);
            } catch (error) {
                console.error(error);
            }
        },

        async userUpdate({ commit }, data) {
            try {
                const { data: dataR } = await http.patch('/users/myself/', data, { authRequired: true });
                commit('storeUser', dataR);
                return Promise.resolve(dataR);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async profile({ commit }) {
            try {
                const { data, status } = await http.get('/auth/', { authRequired: true });
                if (status === 200) {
                    commit('storeUser', data);
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
    getters: {
        isAuthenticated: state => state.isAuthenticated,
        userName: state => state.user.username,
        isAdmin: state => state.user.is_admin,
        isActive: state => state.user.is_active,
        isStaff: state => state.user.is_staff,
        userEmail: state => state.user.email,
        userId: state => state.user.id,
        fullName: state => state.user.fullname,
        permissions: state => state.user.permissions,
    },
};
