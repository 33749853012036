import Vuex from 'vuex';
import loadProject from '../utils/projects';
import auth from './modules/auth';
import storage from './modules/storage';
import subjects from './modules/subjects';
import dialogue from './modules/dialogue';
import history from './modules/history';
import applicationStore from './modules/application';

const modules = loadProject().routes;
const store = new Vuex.Store({
    actions: {
        storeInit({ commit }) {
            const theme = localStorage.getItem('theme');
            if (theme) {
                commit('application/setColorTheme', theme);
            }
        },
    },

    modules: {
        auth,
        storage,
        subjects,
        dialogue,
        history,
        application: applicationStore,
    },
});

const importModule = async (moduleName) => {
    const module = await import(`./modules/${moduleName}`);
    store.registerModule(moduleName, module.default);
};

modules.forEach((moduleName) => {
    importModule(moduleName);
});

export default store;
