<template>
    <div
        class="loader loader_blue"
    />
</template>

<script>
export default {
    name: 'AppLoader',
};
</script>
