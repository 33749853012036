export const pages = {
    name: 'pages',
    icon: 'page',
    title: 'Страницы',
    activeClass: 'pages',
    listNotVisible: [
        {
            title: 'Все страницы',
            name: 'pagesList',
            icon: 'page',
        },
        {
            title: 'Добавить страницу',
            name: 'pagesCreate',
            icon: 'pencil',
        },
    ],
};

export const calculators = {
    name: 'calculators',
    icon: 'calculator',
    title: 'Калькуляторы',
    activeClass: 'calculators',
    listNotVisible: [
        {
            title: 'Все калькуляторы',
            name: 'calculators',
            icon: 'page',
        },
        {
            title: 'Все категории',
            name: 'categories',
            icon: 'calculator',
        },
        {
            title: 'Добавить калькулятор',
            name: 'calculatorCreate',
            icon: 'pencil',
        },
        {
            title: 'Добавить категорию',
            name: 'categoryCreate',
            icon: 'pencil',
        },
    ],
};

export const faq = {
    name: 'faq',
    icon: 'question',
    title: 'Вопросы и ответы',
    activeClass: 'faq',
    listNotVisible: [
        {
            title: 'Все категории',
            name: 'categoriesFaq',
            icon: 'question',
        },
        {
            title: 'Добавить категорию',
            name: 'categoryCreateFaq',
            icon: 'pencil',
        },
    ],
};

export const news = {
    name: 'news',
    icon: 'newspaper',
    title: 'Новости',
    activeClass: 'news',
    listNotVisible: [{
        title: 'Все новости',
        name: 'listNews',
        icon: 'newspaper',
    },
    {
        title: 'Добавить новость',
        name: 'NewsCreate',
        icon: 'pencil',
    },
    ],
};

export const blog = {
    name: 'blog',
    icon: 'pencil',
    title: 'Блог',
    activeClass: 'blog',
    listNotVisible: [
        {
            title: 'Все авторы',
            name: 'BlogAuthors',
            icon: 'list',
        },
        {
            title: 'Все категории',
            name: 'BlogCategory',
            icon: 'list',
        },
        {
            title: 'Все статьи',
            name: 'BlogArticles',
            icon: 'list',
        },
        {
            title: 'Главная страница',
            name: 'BlogMain',
            icon: 'list',
        },
        {
            title: 'Добавить автора',
            name: 'BlogAuthorsCreate',
            icon: 'pencil',
        },
        {
            title: 'Добавить категорию',
            name: 'BlogCategoryAdd',
            icon: 'pencil',
        },
        {
            title: 'Добавить статью',
            name: 'BlogArticlesCreate',
            icon: 'pencil',
        },
        {
            title: 'Статистика поиска',
            name: 'BlogSearchStatistics',
            icon: 'list',
        },
    ],
};

export const comments = {
    name: 'comments',
    icon: 'message',
    activeClass: 'comments',
    title: 'Комментарии',
    listNotVisible: [{
        title: 'Все комментарии',
        name: 'CommentsList',
        icon: 'list',
    },
    ],
};

export const users = {
    name: 'users',
    icon: 'profile',
    activeClass: 'users',
    title: 'Пользователи',
    listNotVisible: [{
        title: 'Все пользователи',
        name: 'AllUsers',
        icon: 'list',
    },
    {
        title: 'Добавить пользователя',
        name: 'AddUser',
        icon: 'pencil',
    },
    ],
};

export const banners = {
    name: 'banners',
    icon: 'page',
    activeClass: 'banners',
    title: 'Баннеры',
    listNotVisible: [{
        title: 'Все баннеры',
        name: 'AllBanners',
        icon: 'list',
    },
    {
        title: 'Добавить баннер',
        name: 'CreateBanner',
        icon: 'pencil',
    },
    ],
};

export const redirects = {
    name: 'redirects',
    icon: 'arrow-right-arrow-left',
    activeClass: 'redirects',
    title: 'Редиректы',
    listNotVisible: [{
        title: 'Все редиректы',
        name: 'AllRedirects',
        icon: 'list',
    },
    {
        title: 'Добавить редирект',
        name: 'AddRedirect',
        icon: 'pencil',
    }],
};

export const neouronet = {
    name: 'neouronet',
    icon: 'ai',
    activeClass: 'neouronet',
    title: 'Нейросеть',
    listNotVisible: [{
        title: 'Все данные',
        name: 'AIDataList',
        icon: 'list',
    }, {
        title: 'Все категории',
        name: 'AllCategoriesNeouronet',
        icon: 'list',
    },
    {
        title: 'Все отчёты',
        name: 'AllAiReports',
        icon: 'list',
    },
    {
        title: 'Добавить категорию',
        name: 'CreateCategoryNeouronet',
        icon: 'pencil',
    },
    ],
};

export const examples = {
    name: 'examples',
    icon: 'list',
    activeClass: 'examples',
    title: 'Примеры работ',
    listNotVisible: [
        {
            title: 'Все иконки предметов',
            name: 'AllExamplesSubjectIcons',
            icon: 'list',
        },
        {
            title: 'Все примеры работ',
            name: 'AllExamples',
            icon: 'list',
        },
        {
            title: 'Добавить иконку предмета',
            name: 'ExampleSubjectIconAdd',
            icon: 'pencil',
        },
        {
            title: 'Добавить пример работ',
            name: 'ExampleAdd',
            icon: 'pencil',
        },
    ],
};

export const dashboards = {
    name: 'dashboards',
    icon: 'page',
    activeClass: 'dashboards',
    title: 'Блоки страниц',
    listNotVisible: [
        {
            title: 'Все виджеты',
            name: 'AllAssets',
            icon: 'list',
        },
        {
            title: 'Все информационные панели',
            name: 'AllBlocks',
            icon: 'list',
        },
        {
            title: 'Добавить виджет',
            name: 'AddAsset',
            icon: 'pencil',
        },
        {
            title: 'Добавить информационную панель',
            name: 'AddBlock',
            icon: 'pencil',
        },
    ],
};
