import {
    pages, calculators, faq, news, blog, comments, users,
    examples, banners, neouronet, redirects, dashboards,
} from './navigationData';

const BASE_URL = process.env.ADMIN_API_BASE_URL || process.env.VUE_APP_PROJECT_URL;

const projects = {
    com: {
        title: 'Admin | Zaochnik.co6m',
        baseURL: BASE_URL,
        routes: ['banners', 'dashboards', 'faq', 'calculators',
            'neouronet', 'news', 'users', 'examples', 'redirects', 'pages'],
        navigation: [
            banners,
            dashboards,
            faq,
            calculators,
            neouronet,
            news,
            users,
            examples,
            redirects,
            pages,
        ],
    },
    ru: {
        title: 'Admin | Zaochnik.ru',
        baseURL: BASE_URL,
        routes: ['blog', 'faq', 'dashboards', 'news', 'users',
            'examples', 'redirects', 'pages', 'comments'],
        navigation: [
            blog,
            dashboards,
            faq,
            comments,
            news,
            users,
            examples,
            redirects,
            pages,
            // banners,
        ],
    },
};

function loadProject() {
    return projects[process.env.VUE_APP_PROJECT];
}

export default loadProject;
