import http from '../http';

export default {
    namespaced: true,

    actions: {
        async uploadFile(context, file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const { data } = await http.post('/files/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    authRequired: true,
                });
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async uploadFileMedia(context, { file, labels, entity }) {
            const formData = new FormData();
            formData.append('file', file);

            labels.forEach(label => formData.append('l', label));

            const cEntity = file.type.startsWith('image/') ? 'image' : 'file';
            formData.append('entity', entity || cEntity);
            try {
                const { data } = await http.post('/files/media/', formData, {
                    authRequired: true,
                });
                return Promise.resolve(data);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async deleteFile(context, pk) {
            try {
                const { status } = await http.delete(`/files/${pk}/`, { authRequired: true });
                return Promise.resolve(status);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async getFiles(context, query) {
            try {
                const { data } = await http.get(`/files/?${query}`, { authRequired: true });
                return Promise.resolve(data);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async updateFiles(context, { id, payload }) {
            try {
                const { data } = await http.patch(`/files/${id}/`, payload, { authRequired: true });
                return Promise.resolve(data);
            } catch (err) {
                return Promise.reject(err);
            }
        },
    },
};
