<template>
    <transition
        appear
        name="float-menu"
    >
        <div
            class="float-menu"
            :style="positionStyle"
            tabindex="0"
            @mouseleave="close"
            @focusout="close"
        >
            <Component
                v-bind="props"
                :is="component"
                :menu-id="id"
            />
        </div>
    </transition>
</template>

<script>

export default {
    name: 'MenuItem',
    props: {
        id: {
            type: String,
            default: '',
        },

        target: HTMLElement,
        component: {
            type: Object,
            default() {
                return {};
            },
        },

        position: {
            type: Object,
            default() {
                return {};
            },
        },

        props: {
            type: Object,
            default() {
                return {};
            },
        },

        windowWidth: {
            type: Number,
            default: 1024,
        },
    },

    data() {
        return {
            coords: {
                top: 0,
                left: 0,
                bottom: 0,
                width: 0,
                height: 0,
            },
            elW: 0,
            elH: 0,
            menuBorder: 16,
        };
    },

    computed: {
        xPos() {
            return Math.round(this.coords.left - this.elW + this.coords.width + this.menuBorder);
        },

        yPos() {
            return Math.round(this.coords.top - this.menuBorder);
        },

        positionStyle() {
            return {
                transform: `translate3d(${this.xPos}px, ${this.yPos}px, 0px)`,
                padding: `${this.menuBorder}px`,
            };
        },
    },

    created() {
        this.$nextTick(() => {
            this.elCoords();
            this.parentCoords();

            window.addEventListener('scroll', this.scrollListener);
        });
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    },

    methods: {
        scrollListener() {
            this.close();
        },

        elCoords() {
            const elS = this.$el.getBoundingClientRect();

            this.elW = elS.width;
            this.elH = elS.height;
        },

        parentCoords() {
            const {
                top, left, bottom, width, height,
            } = this.target.getBoundingClientRect();

            this.coords.top = top;
            this.coords.left = left;
            this.coords.bottom = bottom;
            this.coords.width = width;
            this.coords.height = height;
        },

        close() {
            this.$menu.close(this.id);
        },
    },
};

</script>

<style lang="postcss">

.float-menu {
    position: fixed;
    left: 0;
    top: 0;
    transition: all var(--transition-delay);
    align-self: flex-start;
    pointer-events: auto;

    &.float-menu-enter-active,
    &.float-menu-leave-to {
        opacity: 0;
    }
}

</style>
