import axios from 'axios';
import loadProject from '../utils/projects';
import { compareDates } from '../utils/helpers';
import { getCookie } from '../utils/cookie';
import store from './index';

const BASE_URL = loadProject().baseURL;

const http = axios.create({ baseURL: BASE_URL });

http.interceptors.request.use(async (config) => {
    config.withCredentials = true;

    const JWT_KEY = getCookie('jwt_access');

    if (config.authRequired && JWT_KEY) {
        const difference = compareDates(new Date(), store.state.auth.jwtExpires);
        if (difference <= 2) {
            await store.dispatch('auth/refresh').then(({ access_token: accessToken }) => {
                config.headers.authorization = `Bearer ${accessToken}`;
            });
        }
        config.headers.authorization = `Bearer ${JWT_KEY}`;

        return {
            ...config,
            headers: {
                ...config.headers,
                authorization: `Bearer ${store.state.auth.jwtAccess}`,
            },
        };
    }

    return config;
}, error => Promise.reject(error));

export default http;
