export default {
    namespaced: true,
    state: {
        dialogueValue: false,
    },

    mutations: {
        setDialogueValue(state, payload) {
            state.dialogueValue = payload;
        },
    },
};
