import http from '../http';
import statuses from '../statuses';
import { normalizeDate } from '../../utils/helpers';

export const getFieldName = field => ({
    is_published: 'Публикация',
    content: 'Содержание',
    primary_category_id: 'Основная категория',
    modified: 'Изменено',
    created: 'Добавлено',
})[field] || field;

const {
    empty,
    success,
    loading,
    error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            history: empty,
        },
        history: [],
    },

    actions: {
        async getHistory({ commit }, { pk, id }) {
            commit('setStatus', { type: 'history', val: loading });
            try {
                const { data } = await http.get(`/history/${pk}/${id}/`, { authRequired: true });
                commit('setHistory', data);
                commit('setStatus', { type: 'history', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'history', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setHistory(state, val) {
            const newArr = val.map((item) => {
                const fields = item.fields.map(getFieldName).join(', ');
                const stateT = getFieldName(item.state);
                const date = normalizeDate(item.date);

                return {
                    ...item, fields, state: stateT, date,
                };
            });
            state.history = newArr;
        },
    },

    getters: {
        isLoading: state => state.statuses.history === loading,
        isSuccess: state => state.statuses.history === success,
        isError: state => state.statuses.error === error,
    },
};
